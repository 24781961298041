import FormLabel from "../../Forms/FormLabel/FormLabel";
import { ErrorMessage } from "@hookform/error-message";
import InputError from "../InputError/InputError";

const TextInput = ({
  label,
  placeholder,
  addOnRight,
  addOnLeft,
  textAlign = "left",
  className,
  optional,
  topMargin = "mt-3",
  register,
  disabled,
  name,
  errors,
  defaultValue,
  type = "text",
  helperText,
  validation = {},
  autoComplete,
}) => {
  if (!optional) validation.required = `${label} is required.`;

  return (
    <div className={topMargin}>
      {label && <FormLabel optional={optional}>{label}</FormLabel>}
      <div className={`${label && "mt-1"} flex rounded-md shadow-sm`}>
        {addOnLeft && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-400 sm:text-sm">
            {addOnLeft}
          </span>
        )}
        <input
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          {...register(name, validation)}
          placeholder={placeholder}
          disabled={disabled}
          className={`focus:ring-transparent focus:ring-0 flex-1 text-${textAlign} shadow-sm bg-gray-50 dark:bg-gray-800 dark:text-grey-100 block w-full text-sm border-gray-300 dark:border-gray-600 focus:border-primary-500 dark:focus:border-primary-400 text-black dark:text-white  ${
            addOnRight && "rounded-none rounded-l-md"
          } ${addOnLeft && "rounded-none rounded-r-md"} ${
            !addOnLeft && !addOnRight && "rounded-md"
          } ${className}`}
          type={type}
        />
        {addOnRight && (
          <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-400 sm:text-sm">
            {addOnRight}
          </span>
        )}
      </div>
      {helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <InputError>{message}</InputError>}
      />
    </div>
  );
};

export default TextInput;
