import { useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

import PrimaryButton from "../../../Buttons/PrimaryButton/PrimaryButton";
import Loader from "../../../Common/Loader/Loader";
import TextInput from "../../../Inputs/TextInput/TextInput";
import InputError from "../../../Inputs/InputError/InputError";
import { createTokenCookies } from "../../../../lib/cookie";
import { login } from "../../../../helpers/usersHelper";
import FormHeader from "../../FormHeader/FormHeader";
import FormFooter from "../../FormFooter/FormFooter";
import { useAnalytics } from "../../../../contexts/Segment/Segment";

const LoginForm = () => {
  const router = useRouter();
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const analytics = useAnalytics();

  const initiateLogin = async ({ email, password }) => {
    setLoading(true);
    setError(null);

    try {
      const response = await login(email, password, "WORK_DESK");

      if (response?.tokens) {
        analytics.track("Login");
        createTokenCookies(response.tokens);
        setLoading(false);
        router.push(router.query.redirect || "/dashboard");
      } else {
        throw "Incorrect email or password.";
      }
    } catch (error) {
      setError("Incorrect email or password.");
      setLoading(false);
      console.error(error);
    }
  };

  const onError = (data) => {
    console.log("onError", data);
  };

  return (
    <div className="w-full">
      <div className="p-6 sm:p-12">
        <FormHeader
          title="Welcome back!"
          description="Enter your email and password to login."
        />
        <TextInput
          label="Email"
          register={register}
          type="email"
          name="email"
          disabled={isLoading}
          errors={formState?.errors}
        />
        <TextInput
          label="Password"
          placeholder=""
          register={register}
          type="password"
          name="password"
          disabled={isLoading}
          errors={formState?.errors}
        />
        {error && <InputError>{error}</InputError>}
      </div>
      <FormFooter>
        <div className="px-2 sm:px-6 py-2">
          <PrimaryButton
            onClick={() => handleSubmit(initiateLogin, onError)()}
            size="md"
            fullWidth
            disabled={isLoading}
          >
            <Loader show={isLoading} />
            {!isLoading && "Login"}
          </PrimaryButton>
        </div>
      </FormFooter>
    </div>
  );
};

export default LoginForm;
