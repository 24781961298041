import API from "../lib/axios";
const qs = require("qs");

const createAccount = async (data) => {
  try {
    const response = await API.post(
      "users/admin/new",
      qs.stringify(data, { encode: false })
    );

    if (response.data.result) {
      return response.data.result;
    } else {
      throw response.data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const login = async (email, password, source) => {
  try {
    const response = await API.post(
      "users/login",
      qs.stringify(
        {
          email,
          password,
          source,
        },
        { encode: false }
      )
    );

    if (response.data.result) {
      return response.data.result;
    } else {
      throw response.data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const sendResetPasswordEmail = async (email) => {
  try {
    const response = await API.post(
      "users/password/request",
      qs.stringify(
        {
          email,
        },
        { encode: true }
      )
    );

    if (response.data?.result) {
      return response.data.result;
    } else {
      throw response.data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const validateEncrypt = async (encrypt) => {
  try {
    const response = await API.post(
      "users/password/validate",
      qs.stringify(
        {
          encrypt,
        },
        { encode: false }
      )
    );

    if (response.data?.result) {
      return response.data.result;
    } else {
      throw response.data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const saveNewPassword = async (encrypt, password) => {
  try {
    const response = await API.post(
      "users/update/password/external",
      qs.stringify(
        {
          encrypt,
          password: password.trim(),
        },
        { encode: false }
      )
    );

    if (response.data?.result) {
      return response.data.result;
    } else {
      throw response.data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

export {
  createAccount,
  login,
  sendResetPasswordEmail,
  validateEncrypt,
  saveNewPassword,
};
