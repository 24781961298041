import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { ThemeContext } from "../../../contexts/ThemeContext/ThemeContext";

const Loading = ({ show, size = 5, color }) => {
  let { theme } = React.useContext(ThemeContext);

  // override default color
  if (color) theme = color;

  if (show)
    return (
      <PulseLoader
        color={theme === "dark" ? "#ffffff" : "#000000"}
        loading={true}
        size={size}
      />
    );
  else return <></>;
};

export default Loading;
