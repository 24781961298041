import React from "react";

const FormFooter = ({ children }) => {
  return (
    <div className="px-4 py-3 bg-gray-100 dark:bg-black text-right sm:px-6 rounded-b-lg">
      {children}
    </div>
  );
};

export default FormFooter;
