import React from "react";

const FormLabel = ({ children, optional, className }) => {
  return (
    <label
      htmlFor=""
      className={`flex align-middle justify-between text-sm font-medium text-primary-700 dark:text-primary-300 ${className}`}
    >
      <span>{children}</span>
      {optional && (
        <span className="bg-gray-200 text-gray-500 dark:bg-gray-400 dark:text-gray-900 px-2 py-1 rounded-sm text-xs opacity-90">
          Optional
        </span>
      )}
    </label>
  );
};

export default FormLabel;
