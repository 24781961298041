import Link from "next/link";
import React from "react";

const SmartLink = ({ href, children }) => {
  return (
    <Link href={href}>
      <a className="text-primary-600 dark:text-primary-200 hover:text-primary-500 dark:hover:text-primary-100 hover:underline">
        {children}
      </a>
    </Link>
  );
};

export default SmartLink;
