import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import SmartLink from "../components-reusable/Common/SmartLink/SmartLink";
import LoginForm from "../components-reusable/Forms/AuthenticationForms/LoginForm/LoginForm";
import OnboardingLayout from "../components-reusable/Layouts/OnboardingLayout/OnboardingLayout";
import { ACTIVE_TOKEN_NAME, ALL_TOKENS_NAME } from "../lib/cookie";

export default function LoginPage() {
  const router = useRouter();
  return (
    <OnboardingLayout
      footer={
        <>
          <div className="mb-2">
            Forgot your password?{" "}
            <SmartLink href="/reset-password">Reset Password</SmartLink>
          </div>
          <div>
            Don&lsquo;t have an account?{" "}
            <SmartLink
              href={`/signup${
                router.query.redirect
                  ? `?redirect=${encodeURIComponent(router.query.redirect)}`
                  : ""
              }`}
            >
              Sign up
            </SmartLink>
          </div>
        </>
      }
    >
      <NextSeo title="Login | Tap For Service" description="" />
      <LoginForm />
    </OnboardingLayout>
  );
}

export const getServerSideProps = async (context) => {
  const { req } = context;

  /* check to see if a user is set */
  const authTokenString = req.cookies[ACTIVE_TOKEN_NAME];
  const allTokenString = req.cookies[ALL_TOKENS_NAME];

  if (authTokenString && allTokenString) {
    console.log("User is already logged in, redirecting to dashboard.");
    return { redirect: { destination: "/dashboard", statusCode: 302 } };
  }

  return { props: {} };
};
