import Logo from "../../Common/Logo/Logo";

const OnboardingLayout = ({ children, footer }) => {
  return (
    <>
      <div className="bg-primary-100 dark:bg-gray-900 min-h-screen flex items-center justify-center py-8">
        <div className="w-full">
          <a href="/">
            <Logo width="300" className="m-auto mb-12" />
          </a>
          <div className="bg-white dark:bg-gray-800 sm:rounded-lg overflow-hidden w-full max-w-[500px] mx-auto">
            {children}
          </div>
          {footer && (
            <div className="text-center text-gray-800 dark:text-gray-200 mt-5 text-sm">
              {footer}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingLayout;
